import { Card, FlexBox, Typography } from '@vp/swan'
import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'

export interface Props {
  /**/
}

function App (props: Props) {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)

  useSwanStyleKeys(['core'])

  return (
    <FlexBox>
      <Card style={{ width: '100%' }}>
        <Typography textAllCaps fontWeight='bold' mb={1} mr={1}>
          Hello World from vcs-nextgen-footer fragment!
        </Typography>
      </Card>
    </FlexBox>
  )
}

export default App
